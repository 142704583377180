import { PagedResultModel } from "@models/paged-result.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { LocationGroupModel } from "@models/property/location-group.model";
import locationGroupService from "@services/property/location-group.service";
import { OptionModel } from "@models/global";

class LocationGroupStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<LocationGroupModel> = {
    results: [],
    totalCount: 0,
  };
  params: any = {};
  editLocationGroup: LocationGroupModel = new LocationGroupModel();
  countries: OptionModel[] = [];
  provinces: OptionModel[] = [];
  districts: OptionModel[] = [];
  wards: OptionModel[] = [];

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async init(item: any) {
    this.editLocationGroup = item || new LocationGroupModel();
  }

  async setParams(params: any) {
    this.params = { ...this.params, ...(params || {}) };
  }

  async fetch(params: any) {
    this.isLoading = true;
    let result = await locationGroupService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await locationGroupService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editLocationGroup = await locationGroupService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }

  async add(body: any) {
    this.isLoading = true;
    body.active = true;
    this.editLocationGroup = await locationGroupService
      .add(body)
      .finally(() => (this.isLoading = false));
  }

  async update(body: any) {
    this.isLoading = true;
    this.editLocationGroup = await locationGroupService
      .update(body)
      .finally(() => (this.isLoading = false));
  }

  async updateBulk(body: any) {
    this.isLoading = true;
    const data = await locationGroupService
      .updateBulk(body)
      .finally(() => (this.isLoading = false));
    return data;
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editLocationGroup = await locationGroupService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getProvince(params: any) {
    this.isLoading = true;
    params.level = "2";
    let result = await locationGroupService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.provinces = result.results.map(
      (item) => new OptionModel(item.fullCode, item.name),
    );
    return this.provinces;
  }

  async getDistrict(params: any) {
    this.isLoading = true;
    params.level = "3";
    let result = await locationGroupService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.districts = result.results.map(
      (item) => new OptionModel(item.fullCode, item.name),
    );
    return this.districts;
  }

  async getWards(params: any) {
    this.isLoading = true;
    params.level = "3";
    params.limit = "10000";
    let result = await locationGroupService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.wards = result.results.map(
      (item) => new OptionModel(item.fullCode, item.name),
    );
    return this.districts;
  }
}
export default LocationGroupStore;
