import { PagedResultModel } from "@models/paged-result.model";
import IRevenueModel, { RevenueModel } from "@models/finance/revenue.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import revenueService from "@services/finance/revenue.service";
import { startOfMonth } from "@/src/lib/dayjs";
import { UserRevenueModel as UserBonusModel } from "../../models/finance/user-revenue.model";

class RevenueStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<IRevenueModel> = {
    results: [],
    totalCount: 0,
  };
  editRevenue: RevenueModel = new RevenueModel();
  userBonus?: UserBonusModel = undefined;
  userBonusList?: UserBonusModel[] = undefined;

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initRevenue(Revenue?: any) {
    this.editRevenue = Revenue || new RevenueModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await revenueService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async addRevenue(body: any) {
    this.isLoading = true;
    body.month = startOfMonth(body.month);
    body.active = true;
    this.editRevenue = await revenueService
      .addRevenue(body)
      .finally(() => (this.isLoading = false));
  }

  async updateRevenue(body: any) {
    this.isLoading = true;
    body.month = startOfMonth(body.month);
    this.editRevenue = await revenueService
      .updateRevenue(body)
      .finally(() => (this.isLoading = false));
  }

  async getMonthBonusByUser(userId: string, month: Date) {
    this.isLoading = true;
    month = startOfMonth(month);
    this.userBonus = await revenueService
      .getMonthBonusByUser(userId, month)
      .finally(() => (this.isLoading = false));
    return this.userBonus;
  }

  async getAllMonthRevenue(month: Date) {
    this.isLoading = true;
    month = startOfMonth(month);
    this.userBonusList = await revenueService
      .getAllMonthRevenue(month)
      .finally(() => (this.isLoading = false));
    return this.userBonusList;
  }

  async addBulkRevenue(body: any) {
    this.isLoading = true;
    await revenueService
      .addBulkRevenue(body)
      .finally(() => (this.isLoading = false));
  }

  async updateBulkRevenue(body: any) {
    this.isLoading = true;
    await revenueService
      .updateBulkRevenue(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editRevenue = await revenueService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editRevenue = await revenueService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default RevenueStore;
