import { PAYMENT_METHOD } from "@/src/lib/constants";

interface IOrderModel {
  id: string;
  siteId: any;
  userId?: number;
  orderNumber: string;
  orderDate?: Date;
  totalAmount: number;
  description?: string;
  paymentProvider?: string;
  paymentMethod: string;
  status: string;
}

export class OrderModel implements IOrderModel {
  id: string = "";
  siteId: any = "";
  userId?: number;
  orderNumber: string = "";
  orderDate?: Date;
  totalAmount: number = 0;
  description?: string;
  paymentProvider?: string;
  paymentMethod: string = "CASH";
  status: string = "active";

  orderDetails: any[] = [];

  constructor() {
    this.orderDetails = [];
    this.paymentMethod = PAYMENT_METHOD.cash;
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new OrderModel(), obj);
    newObj.orderDate = obj.orderDate ? new Date(obj.orderDate) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    (objs || []).forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export class OrderDetail {
  id?: string;
  orderId?: string;
  productId: string = "";
  product: any;
  quantity: number = 1;
  unitPrice: number = 0;
  amount: number = 0;
  size?: string;

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new OrderDetail(), obj);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    (objs || []).forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IOrderModel;
