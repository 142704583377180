import { PagedResultModel } from "@models/paged-result.model";
import IPostModel, { PostModel } from "@models/post/post.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import postService from "@services/post.service";

class PostStore {
  isLoading: boolean = false;
  aiPostParams: any;
  pagedResult: PagedResultModel<IPostModel> = {
    results: [],
    totalCount: 0,
  };
  simplePostResult: PagedResultModel<IPostModel> = {
    results: [],
    totalCount: 0,
  };
  editPost: PostModel = new PostModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setAiPostParams(params: any) {
    this.aiPostParams = params;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await postService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async filterSimplePost(params: any) {
    this.isLoading = true;
    let result = await postService
      .filterSimplePost(params)
      .finally(() => (this.isLoading = false));
    if (params.page > 1) {
      this.simplePostResult = {
        results: [...this.simplePostResult.results, ...result.results],
        totalCount: result.totalCount,
      };
    } else {
      this.simplePostResult = result;
    }

    return result;
  }

  async addPost(body: any) {
    this.isLoading = true;
    this.editPost = await postService
      .addPost(body)
      .finally(() => (this.isLoading = false));
  }

  async updatePost(body: any) {
    this.isLoading = true;
    this.editPost = await postService
      .updatePost(body)
      .finally(() => (this.isLoading = false));
  }

  async updateBulkPostMeta(body: any) {
    this.isLoading = true;
    await postService
      .updateBulkPostMeta(body)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editPost = await postService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default PostStore;
