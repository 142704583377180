import { PagedResultModel } from "@models/paged-result.model";
import IPermissionModel, {
  IPermissionGroupModel,
  PermissionModel,
} from "@models/system/permission.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import permissionService from "@services/system/permission.service";
import groupBy from "lodash/groupBy";

class PermissionStore {
  isLoading: boolean = false;
  aiPermissionParams: any;
  pagedResult: PagedResultModel<IPermissionModel> = {
    results: [],
    totalCount: 0,
  };
  permissionGroups: IPermissionGroupModel[] = [];
  editPermission: PermissionModel = new PermissionModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setEditingPermission(permission: any) {
    this.editPermission = permission;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await permissionService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
  }

  async getAll(params: any) {
    this.isLoading = true;
    let result = await permissionService
      .filter(params)
      .finally(() => (this.isLoading = false));
    let groups = groupBy(result.results, "group");
    this.permissionGroups = Object.keys(groups).map((key) => {
      return { group: key, permissions: groups[key] };
    });
  }

  async addPermission(body: any) {
    this.isLoading = true;
    this.editPermission = await permissionService
      .addPermission(body)
      .finally(() => (this.isLoading = false));
  }

  async updatePermission(body: any) {
    this.isLoading = true;
    this.editPermission = await permissionService
      .updatePermission(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editPermission = await permissionService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editPermission = await permissionService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default PermissionStore;
