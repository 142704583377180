import { PagedResultModel } from "@models/paged-result.model";
import http from "../http.service";
import IRevenueModel, { RevenueModel } from "@models/finance/revenue.model";
import { UserRevenueModel } from "@models/finance/user-revenue.model";

class RevenueService {
  public async filter(params: any): Promise<PagedResultModel<IRevenueModel>> {
    let result = await http.get("/api/finance/revenue", { params });

    result.data.results = RevenueModel.assigns(result.data.results);
    return result.data;
  }

  public async addRevenue(body: any) {
    let result = await http.post("/api/finance/revenue", body);
    return RevenueModel.assign(result.data.results);
  }

  public async updateRevenue(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/finance/revenue`, data);
    return RevenueModel.assign(result.data.results);
  }

  public async getMonthBonusByUser(userId: string, month: Date) {
    let result = await http.get(`/api/finance/revenue/month/${userId}`, {
      params: { month },
    });

    return UserRevenueModel.assign(result.data.results);
  }

  public async getAllMonthRevenue(month: Date) {
    let result = await http.get(`/api/finance/revenue/month`, {
      params: { month },
    });

    return UserRevenueModel.assigns(result.data.results);
  }

  public async addBulkRevenue(body: any) {
    let result = await http.post("/api/finance/revenue/bulk", body);
    return RevenueModel.assigns(result.data.results);
  }

  public async updateBulkRevenue(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/finance/revenue/bulk`, data);
    return RevenueModel.assigns(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/finance/revenue/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return RevenueModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/finance/revenue/${id}`);
    return RevenueModel.assign(result.data.results);
  }
}
const masterDataService = new RevenueService();
export default masterDataService;
