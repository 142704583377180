export const PROJECT_TYPE = [
  {
    code: "apartment_flat",
    name: "Apartment / Flat",
    group: "PROJECT_TYPE",
  },
  {
    code: "house",
    name: "House",
    group: "PROJECT_TYPE",
  },
  {
    code: "villa",
    name: "Villa",
    group: "PROJECT_TYPE",
  },
  {
    code: "village_home",
    name: "Village Home",
    group: "PROJECT_TYPE",
  },
  {
    code: "walk_up",
    name: "Walk Up",
    group: "PROJECT_TYPE",
  },
  {
    code: "serviced_apartment",
    name: "Serviced Apartment",
    group: "PROJECT_TYPE",
  },
  {
    code: "land_plot",
    name: "Land Plot",
    group: "PROJECT_TYPE",
  },
  {
    code: "condominium",
    name: "Condominium",
    group: "PROJECT_TYPE",
  },
  {
    code: "townhouse",
    name: "Townhouse",
    group: "PROJECT_TYPE",
  },
  {
    code: "cluster_house",
    name: "Cluster House",
    group: "PROJECT_TYPE",
  },
  {
    code: "executive-condominium",
    name: "Executive Condominium",
    group: "PROJECT_TYPE",
  },
  {
    code: "hdb_apartment",
    name: "HDB Apartment",
    group: "PROJECT_TYPE",
  },
  {
    code: "detached",
    name: "Detached",
    group: "PROJECT_TYPE",
  },
  {
    code: "semi-detached",
    name: "Semi-Detached",
    group: "PROJECT_TYPE",
  },
  {
    code: "bungalow",
    name: "Bungalow",
    group: "PROJECT_TYPE",
  },
  {
    code: "shop_house",
    name: "Shop house",
    group: "PROJECT_TYPE",
  },
  {
    code: "landed_property",
    name: "Landed property",
    group: "PROJECT_TYPE",
  },
  {
    code: "officetel",
    name: "Officetel",
    group: "PROJECT_TYPE",
  },
  {
    code: "condotel",
    name: "Condotel",
    group: "PROJECT_TYPE",
  },
  {
    code: "corner_condo",
    name: "Corner Condo",
    group: "PROJECT_TYPE",
  },
  {
    code: "lateral_condominium",
    name: "Lateral Condominium",
    group: "PROJECT_TYPE",
  },
];

export const AMENITY = [
  {
    code: "balcony",
    name: "Balcony",
  },
  {
    code: "roof_terrace",
    name: "Roof Terrace",
  },
  {
    code: "walk_in_closet",
    name: "Walk in Closet",
  },
  {
    code: "study_room",
    name: "Study Room",
  },
  {
    code: "yard",
    name: "Yard",
  },
  {
    code: "helper_quarters",
    name: "Helper's Quarters",
  },
  {
    code: "utility_area",
    name: "Utility Area",
  },
  {
    code: "jacuzzi",
    name: "Jacuzzi",
  },
  {
    code: "aerobic_room",
    name: "Aerobic Room",
  },
  {
    code: "gymnasium",
    name: "Gymnasium",
  },
  {
    code: "fitness_center",
    name: "Fitness Center",
  },
  {
    code: "badminton_court",
    name: "Badminton Court",
  },
  {
    code: "basketball_court",
    name: "Basketball Court",
  },
  {
    code: "function_room",
    name: "Function Room",
  },
  {
    code: "children_playground",
    name: "Children's playground",
  },
  {
    code: "club_house",
    name: "Club house",
  },
  {
    code: "billiards_room",
    name: "Billiards Room",
  },
  {
    code: "golf_driving_range",
    name: "Golf Driving Range",
  },
  {
    code: "squash_court",
    name: "Squash Court",
  },
  {
    code: "table_tennis",
    name: "Table Tennis",
  },
  {
    code: "tennis_court",
    name: "Tennis Court",
  },
  {
    code: "swimming_pool",
    name: "Swimming Pool",
  },
  {
    code: "garden",
    name: "Garden",
  },
  {
    code: "meeting_oom",
    name: "Meeting Room",
  },
  {
    code: "games_room",
    name: "Games Room",
  },
  {
    code: "sauna_room",
    name: "Sauna Room",
  },
  {
    code: "yoga_room",
    name: "Yoga Room",
  },
  {
    code: "steam_room",
    name: "Steam Room",
  },
  {
    code: "library",
    name: "Library",
  },
  {
    code: "bbq",
    name: "BBQ",
  },
  {
    code: "spa",
    name: "Spa",
  },
  {
    code: "lift",
    name: "Lift",
  },
];

export const TRANSPORTATION = [
  {
    code: "ferry",
    name: "Ferry",
  },
  {
    code: "mtr",
    name: "MTR",
  },
  {
    code: "buses",
    name: "Buses",
  },
  {
    code: "shuttle_bus",
    name: "Shuttle Bus",
  },
  {
    code: "bts",
    name: "BTS",
  },
  {
    code: "mrt",
    name: "MRT",
  },
  {
    code: "lrt",
    name: "LRT",
  },
  {
    code: "river_taxi",
    name: "River Taxi",
  },
  {
    code: "brt",
    name: "BRT",
  },
  {
    code: "marina",
    name: "Marina",
  },
  {
    code: "water_bus",
    name: "Water bus",
  },
  {
    code: "river_boat",
    name: "River Boat",
  },
  {
    code: "golf_cart",
    name: "Golf Cart",
  },
  {
    code: "taxi_water",
    name: "Taxi Water",
  },
];

export const FURNITURE = [
  {
    name: "Đầy đủ",
    code: "full",
  },
  {
    name: "Chưa có",
    code: "miss",
  },
];

export const SOVEREIGNTY = [
  {
    name: "Sổ hồng",
    code: "ownership_certificate",
  },
  {
    name: "Hợp đồng",
    code: "contract",
  },
];

export const USES = [
  {
    name: "Để ở",
    code: "stay",
  },
  {
    name: "Kinh doanh",
    code: "business",
  },
  {
    name: "Cho thuê",
    code: "rent",
  },
];

export const PROPERTY_GROUP = [
  {
    name: "Bán",
    code: "sale",
  },
  {
    name: "Cho thuê",
    code: "lease",
  },
];

export const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];
