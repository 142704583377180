import { PagedResultModel } from "@models/paged-result.model";
import http from "@services/http.service";
import { OrganizationModel } from "@models/property/organization.model";

class OrganizationService {
  public async filter(
    params: any,
  ): Promise<PagedResultModel<OrganizationModel>> {
    let result = await http.get("/api/organization", { params });

    result.data.results = OrganizationModel.assigns(result.data.results);
    return result.data;
  }

  public async addOrganization(body: any) {
    let result = await http.post("/api/organization", body);
    return OrganizationModel.assign(result.data.results);
  }

  public async updateOrganization(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/organization`, data);
    return OrganizationModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/organization/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return OrganizationModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/organization/${id}`);
    return OrganizationModel.assign(result.data.results);
  }
}
const organizationService = new OrganizationService();
export default organizationService;
