import { PagedResultModel } from "@models/paged-result.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { ProjectModel } from "@models/property/project.model";
import projectService from "@services/property/project.service";

class ProjectStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<ProjectModel> = {
    results: [],
    totalCount: 0,
  };
  params: any = {};
  editProject: ProjectModel = new ProjectModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async setParams(params: any) {
    this.params = { ...this.params, ...(params || {}) };
  }

  async createProject() {
    this.editProject = new ProjectModel();
  }

  async fetch(params: any) {
    this.isLoading = true;
    let result = await projectService
      .fetch(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult.results = result;
    return this.pagedResult;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await projectService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editProject = await projectService
      .get(id)
      .finally(() => (this.isLoading = false));

    return this.editProject;
  }

  async getBySlug(slug: string) {
    this.isLoading = true;
    this.editProject = await projectService
      .getBySlug(slug)
      .finally(() => (this.isLoading = false));

    return this.editProject;
  }

  async getBySlugForPortal(slug: string) {
    this.isLoading = true;
    this.editProject = await projectService
      .getBySlugForPortal(slug)
      .finally(() => (this.isLoading = false));

    return this.editProject;
  }

  async addProject(body: any) {
    this.isLoading = true;
    body.active = true;
    body.photo = body.photo ?? "";
    this.editProject = await projectService
      .addProject(body)
      .finally(() => (this.isLoading = false));
  }

  async updateProject(body: any) {
    this.isLoading = true;
    this.editProject = await projectService
      .updateProject(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editProject = await projectService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }
}
export default ProjectStore;
