import { PagedResultModel } from "@models/paged-result.model";
import IPermissionModel, {
  PermissionModel,
} from "@models/system/permission.model";
import http from "../http.service";

class PermissionService {
  public async filter(
    params: any,
  ): Promise<PagedResultModel<IPermissionModel>> {
    let result = await http.get("/api/system/permission", { params });

    result.data.results = PermissionModel.assigns(result.data.results);
    return result.data;
  }

  public async addPermission(body: any) {
    let result = await http.post("/api/system/permission", body);
    return PermissionModel.assign(result.data);
  }

  public async updatePermission(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/system/permission`, data);
    return PermissionModel.assign(result.data);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/system/permission/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return PermissionModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/v1/ai-post/${id}`);
    return PermissionModel.assign(result.data);
  }
}
const masterDataService = new PermissionService();
export default masterDataService;
