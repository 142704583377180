import { PagedResultModel } from "@models/paged-result.model";
import IMasterDataModel, {
  MasterDataModel,
} from "@models/system/master-data.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import masterDataService from "@services/system/master-data.service";
import { OptionModel } from "@models/global";
import {
  AMENITY,
  FURNITURE,
  PROJECT_TYPE,
  PROPERTY_GROUP,
  SOVEREIGNTY,
  TRANSPORTATION,
  USES,
} from "@/src/data/property-const";

class MasterDataStore {
  isLoading: boolean = false;
  aiMasterDataParams: any;
  pagedResult: PagedResultModel<IMasterDataModel> = {
    results: [],
    totalCount: 0,
  };
  departments: OptionModel[] = [];
  positions: OptionModel[] = [];
  revenueSources: OptionModel[] = [];
  editMasterData: MasterDataModel = new MasterDataModel();

  // Property
  propertyGroups: OptionModel[] = [];
  propertyTypes: OptionModel[] = [];
  projectTypes: OptionModel[] = [];
  amenities: OptionModel[] = [];
  transportations: OptionModel[] = [];
  uses: OptionModel[] = [];
  sovereignty: OptionModel[] = [];
  furniture: OptionModel[] = [];

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.projectTypes = PROJECT_TYPE.map(
      (item) => new OptionModel(item.code, item.name),
    );

    this.amenities = AMENITY.map(
      (item) => new OptionModel(item.code, item.name),
    );

    this.transportations = TRANSPORTATION.map(
      (item) => new OptionModel(item.code, item.name),
    );

    this.uses = USES.map((item) => new OptionModel(item.code, item.name));

    this.furniture = FURNITURE.map(
      (item) => new OptionModel(item.code, item.name),
    );

    this.sovereignty = SOVEREIGNTY.map(
      (item) => new OptionModel(item.code, item.name),
    );

    this.propertyGroups = PROPERTY_GROUP.map(
      (item) => new OptionModel(item.code, item.name),
    );
  }

  setAiMasterDataParams(params: any) {
    this.aiMasterDataParams = params;
  }

  async init(item: any) {
    this.editMasterData = item || new MasterDataModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await masterDataService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
  }

  async getDepartments(params: any) {
    this.isLoading = true;
    params.group = "DEPARTMENT";
    let result = await masterDataService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.departments = result.results.map(
      (item) => new OptionModel(item.id, item.name),
    );
    return this.departments;
  }

  async getPositions(params: any) {
    this.isLoading = true;
    params.group = "POSITION";
    let result = await masterDataService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.positions = result.results.map(
      (item) => new OptionModel(item.id, item.name),
    );
    return this.positions;
  }

  async getRevenueSources(params: any) {
    this.isLoading = true;
    params.group = "REVENUE_SOURCE";
    let result = await masterDataService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.revenueSources = result.results.map(
      (item) => new OptionModel(item.id, item.name),
    );
    return this.revenueSources;
  }

  async addMasterData(body: any) {
    this.isLoading = true;
    this.editMasterData = await masterDataService
      .addMasterData(body)
      .finally(() => (this.isLoading = false));
  }

  async updateMasterData(body: any) {
    this.isLoading = true;
    this.editMasterData = await masterDataService
      .updateMasterData(body)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editMasterData = await masterDataService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editMasterData = await masterDataService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }
}
export default MasterDataStore;
