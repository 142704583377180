import IOrderModel, {
  OrderDetail,
  OrderModel,
} from "@models/finance/order.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { PagedResultModel } from "@/src/models/paged-result.model";

class OrderStore {
  isLoading: boolean = false;
  showDrawer?: boolean = false;
  showCartItemDrawer?: boolean = false;
  pagedResult: PagedResultModel<IOrderModel> = {
    results: [],
    totalCount: 0,
  };
  editOrder: OrderModel = new OrderModel();
  editOrderDetail?: OrderDetail = new OrderDetail();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async showCartBottomSheet() {
    this.showDrawer = !this.showDrawer;
  }

  async showCartItemBottomSheet(product?: any) {
    this.initCartItem(product);
    this.showCartItemDrawer = !this.showCartItemDrawer;
  }

  async initOrder(order?: any) {
    this.editOrder = order || new OrderModel();
  }

  async initCartItem(product?: any) {
    if (!product) {
      this.editOrderDetail = undefined;
      return;
    }
    const orderItemIndex = this.editOrder.orderDetails.findIndex(
      (item) => item.productId === product.id,
    );

    if (orderItemIndex === -1) {
      this.editOrderDetail = OrderDetail.assign({
        product,
        productId: product.id,
        quantity: 1,
        unitPrice: product.price,
        amount: product.price,
        size: "medium",
      });
    } else {
      this.editOrderDetail = this.editOrder.orderDetails[orderItemIndex];
    }
  }

  async updateOrder(order: any) {
    this.isLoading = true;
  }

  async addCartItem(product: any, values: any) {
    if (!values) {
      return;
    }
    if (this.editOrder.orderDetails.some((x) => x.productId === product.id)) {
      this.editOrder.orderDetails = this.editOrder.orderDetails.map((x) => {
        if (x.productId === product.id) {
          x = { ...x, ...values };
          x.amount = x.quantity * x.unitPrice;
        }
        return x;
      });
    } else {
      this.editOrder.orderDetails.push({
        ...values,
        productId: product.id,
        product: product,
        unitPrice: values.unitPrice,
        quantity: values.quantity,
        amount: values.quantity * values.unitPrice,
      });
    }
    this.initOrder({ ...this.editOrder });
  }

  async removeOrderDetail(index: number) {
    if (
      !this.editOrder.orderDetails ||
      index >= this.editOrder.orderDetails.length
    ) {
      return;
    }
    this.editOrder.orderDetails.splice(index, 1);
  }

  async updateOrderDetailQuantity(index: number, quantity: number) {
    if (
      !this.editOrder.orderDetails ||
      index >= this.editOrder.orderDetails.length
    ) {
      return;
    }
    this.editOrder.orderDetails[index].quantity = quantity;
  }
}
export default OrderStore;
