import { ISystemModel } from "../global";

interface IPermissionModel {
  name: string;
  group: string;
  active: boolean;
}

export interface IPermissionGroupModel {
  group: string;
  permissions: IPermissionModel[];
}

export class PermissionModel implements IPermissionModel, ISystemModel {
  id: string = "";
  name: string = "";
  group: string = "";
  active: boolean = true;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new PermissionModel(), obj);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IPermissionModel;
