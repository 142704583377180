import { ISystemModel } from "../global";

interface ICategoryModel {
  name: string;
  slug: string;
  status: string;
  posts: any;
}

export class CategoryModel implements ICategoryModel, ISystemModel {
  id: string = "";
  name: string = "";
  slug: string = "";
  status: string = "active";
  siteId: string | null = null;
  posts: any;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new CategoryModel(), obj);
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default ICategoryModel;
