interface IPostModel {
  title: string;
  status: string;
}

export class SimplePostModel implements IPostModel {
  id: string = "";
  title: string;
  source: string;
  link: string = "";
  featuredImage: string = "";
  status: string;
  virality: number = 0;
  clicks: number = 0;
  isFrameAllowed: boolean = false;
  userId: string = "";
  date: Date = new Date();

  constructor() {
    this.title = "";
    this.source = "";
    this.status = "";
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new SimplePostModel(), obj);
    newObj.featuredImage = obj.image;
    newObj.date = new Date(obj.created);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IPostModel;
