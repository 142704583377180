import { ISystemModel } from "../global";

interface IRevenueModel {
  userId: string;
  user: any;
  status: string;
  sourceId?: string;
  source: any;
  revenue: number;
  month: Date;
}

export class RevenueModel implements IRevenueModel, ISystemModel {
  id: string = "";
  userId: string = "";
  user: any;
  status: string = "active";
  sourceId: string = "";
  source: any;
  revenue: number = 0;
  month: Date = new Date();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new RevenueModel(), obj);
    newObj.month = obj.month ? new Date(obj.month) : new Date();
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    newObj.active = obj.status === "active";
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IRevenueModel;
