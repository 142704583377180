import { UserModel } from "../system/user.model";

interface IPostModel {
  title: string;
  keywords: string;
  status: string;
}

export class PostModel implements IPostModel {
  id: string = "";
  slug: string = "";
  title: string;
  keywords: string;
  outlines: string = "";
  description: string = "";
  content: string = "";
  status: string = "";
  userId: string = "";
  image: string = "";
  categories: string | null = null;
  tags: string | null = null;
  imageBlurhash: string | null = null;
  isFeatured: boolean = false;
  siteId: string | null = null;
  campaignId: string | null = null;
  originalData: string | null = null;
  user: UserModel = new UserModel();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {
    this.title = "";
    this.keywords = "";
    this.status = "";
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new PostModel(), obj);
    newObj.createdAt = new Date(obj.createdAt);
    newObj.updatedAt = new Date(obj.updatedAt);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IPostModel;
