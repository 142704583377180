import { ISystemModel } from "../global";

interface ICampaignModel {
  name: string;
  budget: number; // Budget per item
  max: number; // Max items
  remaining: number;
  status: string;
  posts: any;
}

export class CampaignModel implements ICampaignModel, ISystemModel {
  id: string = "";
  name: string = "";
  budget: number = 0;
  max: any;
  remaining: number = 0;
  posts: any = [];
  status: string = "active";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new CampaignModel(), obj);
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    newObj.remaining = obj.max - (obj.posts || []).length;
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default ICampaignModel;
