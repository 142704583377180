import { AppConsts } from "src/lib/constants";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";
const { authorization } = AppConsts;

const http = axios.create({
  baseURL: process.env.API_URL,
  timeout: 60000,
});

http.interceptors.request.use(
  function (config: any) {
    if (!config.headers) {
      config.headers = {};
    }

    if (!!Cookies.get(authorization.encryptedAuthTokenName)) {
      config.headers["Authorization"] =
        "Bearer " + Cookies.get(authorization.encryptedAuthTokenName);
    }

    config.params = {
      ...(config.params || {}),
    };

    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (responseError) => {
    if (responseError?.response?.status === HTTP_ERROR_CODES.UNAUTHENTICATED) {
      Cookies.remove(AppConsts.authorization.encryptedAuthTokenName, {
        path: "",
      });
      Cookies.remove(AppConsts.authorization.currentUser, {
        path: "",
      });
      window.location.href = "/error/401";
      toast.error("You are not authorized to access this page.");
      return Promise.reject(responseError);
    } else if (
      responseError?.response?.status === HTTP_ERROR_CODES.BAD_REQUEST
    ) {
      const { data } = responseError?.response || {};
      return Promise.reject(data);
    }

    const { data } = responseError?.response || {};
    if (!data) {
      // toast.error(responseError);
      console.log(responseError);
      return Promise.reject({
        message: "UNKNOWN_ERROR",
      });
    }

    if (!!data.message && data.details) {
      toast.error(data.details);
    } else if (data.message) {
      toast.error(data.message);
    }

    return Promise.reject(data);
  },
);

const HTTP_ERROR_CODES = {
  UNAUTHENTICATED: 401,
  BAD_REQUEST: 400,
};

// External query
export const httpExternal = axios.create({
  timeout: 60000,
});

httpExternal.interceptors.request.use(
  function (config: any) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers["X-Api-Key"] = "KpB60v58TRrcyycBnktLjrmI";

    config.params = {
      ...(config.params || {}),
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default http;
