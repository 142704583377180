import { PagedResultModel } from "@models/paged-result.model";
import { MenuItemModel } from "@/src/models/cms/menu-item.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import menuService from "@services/cms/menu.service";

class MenuStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<MenuItemModel> = {
    results: [],
    totalCount: 0,
  };
  editMenu: MenuItemModel = new MenuItemModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initMenu(Menu?: any) {
    this.editMenu = Menu || new MenuItemModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await menuService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async add(body: any) {
    this.isLoading = true;
    this.editMenu = await menuService
      .addMenu(body)
      .finally(() => (this.isLoading = false));
  }

  async update(body: any) {
    this.isLoading = true;
    this.editMenu = await menuService
      .updateMenu(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editMenu = await menuService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editMenu = await menuService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default MenuStore;
