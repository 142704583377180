import { PagedResultModel } from "@models/paged-result.model";
import IUserModel, { UserModel } from "@models/system/user.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import userService from "@services/system/user.service";
import { OptionModel } from "@models/global";
import { SalaryMasterModel } from "@models/finance/salary-master.model";

class UserStore {
  isLoading: boolean = false;
  aiUserParams: any;
  pagedResult: PagedResultModel<IUserModel> = {
    results: [],
    totalCount: 0,
  };
  editUser: UserModel = new UserModel();
  userOptions: OptionModel[] = [];
  siteOptions: OptionModel[] = [];
  salaryMaster: SalaryMasterModel = new SalaryMasterModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setAiUserParams(params: any) {
    this.aiUserParams = params;
  }

  async init(item: any) {
    this.editUser = item || new UserModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await userService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return result;
  }

  async addUser(body: any) {
    this.isLoading = true;
    this.editUser = await userService
      .addUser(body)
      .finally(() => (this.isLoading = false));
  }

  async updateUser(body: any) {
    this.isLoading = true;
    this.editUser = await userService
      .updateUser(body)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editUser = await userService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }

  async getUserOptions() {
    this.isLoading = true;
    let result = await userService
      .filter({ pageSize: 1000, pageIndex: 1 })
      .finally(() => (this.isLoading = false));
    this.userOptions = result.results.map((item) => {
      return new OptionModel(item.id, item.name);
    });
    return this.userOptions;
  }
  async getSiteOptions(params: any) {
    this.isLoading = true;
    params.page = 1;
    params.limit = 100;
    let result = await userService
      .filterSites(params)
      .finally(() => (this.isLoading = false));
    this.siteOptions = result.results;
    return this.siteOptions;
  }

  async getSalaryMasterByUserId(userId: any) {
    this.isLoading = true;
    this.salaryMaster = await userService
      .getSalaryMasterByUserId(userId)
      .finally(() => (this.isLoading = false));

    return this.salaryMaster;
  }

  async addSalaryMaster(body: any) {
    this.isLoading = true;
    this.salaryMaster = await userService
      .addSalaryMaster(body)
      .finally(() => (this.isLoading = false));
  }

  async updateSalaryMaster(body: any) {
    this.isLoading = true;
    this.salaryMaster = await userService
      .updateSalaryMaster(body)
      .finally(() => (this.isLoading = false));
  }
}
export default UserStore;
