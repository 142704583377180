import { SalaryMaster } from "@prisma/client";
import { ISystemModel } from "../global";

interface IUserModel {
  id: string;
  name: string;
  gh_username: string;
  emailVerified?: boolean;
  image?: string;
  roleId: string;
  salaryMaster?: SalaryMaster;
}

export class UserModel implements IUserModel, ISystemModel {
  id: string = "";
  name: string = "";
  gh_username: string = "";
  roleId: string = "";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new UserModel(), obj);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IUserModel;
