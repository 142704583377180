import { PagedResultModel } from "@models/paged-result.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { PropertyModel } from "@models/property/property.model";
import propertyService from "@services/property/property.service";

class PropertyStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<PropertyModel> = {
    results: [],
    totalCount: 0,
  };
  params: any = {
    group: "sale",
  };
  editProperty: PropertyModel = new PropertyModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async setParams(filter: any) {
    this.params = { ...this.params, ...(filter || {}) };
  }

  async createProperty() {
    this.editProperty = new PropertyModel();
  }

  async fetch(params: any) {
    this.isLoading = true;
    let result = await propertyService
      .fetch(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult.results = result;
    return this.pagedResult;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await propertyService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editProperty = await propertyService
      .get(id)
      .finally(() => (this.isLoading = false));
  }

  async getByIdForPortal(id: string) {
    this.isLoading = true;
    this.editProperty = await propertyService
      .getByIdForPortal(id)
      .finally(() => (this.isLoading = false));
  }

  async getBySlug(slug: string) {
    this.isLoading = true;
    this.editProperty = await propertyService
      .getBySlug(slug)
      .finally(() => (this.isLoading = false));

    return this.editProperty;
  }

  async addProperty(body: any) {
    this.isLoading = true;
    body.active = true;
    body.photo = body.photo ?? "";
    this.editProperty = await propertyService
      .addProperty(body)
      .finally(() => (this.isLoading = false));
  }

  async updateProperty(body: any) {
    this.isLoading = true;
    this.editProperty = await propertyService
      .updateProperty(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editProperty = await propertyService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }
}
export default PropertyStore;
