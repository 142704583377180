import { ISystemModel } from "../global";

interface IRequestContactModel {
  name: string;
  email: string;
  phone: string;
  projectId?: string;
  propertyId?: string;
  sellerId?: string;
  status: string;
}

export class RequestContactModel implements IRequestContactModel, ISystemModel {
  id: string = "";
  name: string = "";
  email: string = "";
  phone: string = "";
  projectId?: string;
  propertyId?: string;
  sellerId?: string;
  status: string = "active";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new RequestContactModel(), obj);
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IRequestContactModel;
