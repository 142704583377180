import { ISystemModel } from "../global";

interface IMasterDataModel {
  name: string;
  code: string;
  status: string;
}

export class MasterDataModel implements IMasterDataModel, ISystemModel {
  id: string = "";
  name: string = "";
  code: string = "";
  status: string = "";
  active: boolean = true;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {
    this.status = "";
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new MasterDataModel(), obj);
    newObj.active = obj.status === "active";
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IMasterDataModel;
