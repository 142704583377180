import ISignUpModel from "@models/account/signupModel";
import Cookies from "js-cookie";
import { makeAutoObservable } from "mobx";
import { AppConsts } from "src/lib/constants";
import ISignInModel, { IUserModel } from "@models/account/signinModel";
import accountService from "@services/account.service";
import { RootStore } from "./";

class AuthenticationStore {
  isLoading: boolean = false;
  showProfile: boolean = false;
  showSignInDrawer: boolean = false;
  showSignUpDrawer: boolean = false;
  loginModel: ISignInModel | undefined;
  user?: IUserModel;
  error?: string;

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    if (
      this.isAuthenticated &&
      Cookies.get(AppConsts.authorization.currentUser)
    ) {
      this.user = JSON.parse(
        Cookies.get(AppConsts.authorization.currentUser) || "",
      );
    }
  }

  get isAuthenticated(): boolean {
    return !!Cookies.get(AppConsts.authorization.encryptedAuthTokenName);
  }

  async setShowProfile(value: boolean) {
    this.showProfile = value;
  }

  async setShowSignIn(value: boolean) {
    this.showSignInDrawer = value;
  }

  async setShowSignUp(value: boolean) {
    this.showSignUpDrawer = value;
  }

  async signUp(model: ISignUpModel) {
    this.isLoading = true;
    let result = await accountService
      .signUp(model)
      .finally(() => (this.isLoading = false))
      .catch((error) => {
        this.error = error.message;
        return undefined;
      });
    // Return if error
    if (!result) return;
    this.error = undefined;
    this.user = result.user;

    Cookies.set(
      AppConsts.authorization.encryptedAuthTokenName,
      result.tokens.access.token,
      {
        expires: 7, //result.tokens.access.expires
        secure: true,
        sameSite: "strict",
      },
    );
    Cookies.set(
      AppConsts.authorization.currentUser,
      JSON.stringify(result.user),
      {
        expires: 7, //result.tokens.access.expires
        secure: true,
        sameSite: "strict",
      },
    );
  }

  async signIn(model: ISignInModel) {
    this.isLoading = true;
    let result = await accountService
      .signIn(model)
      .finally(() => (this.isLoading = false));
    this.user = result.user;

    Cookies.set(
      AppConsts.authorization.encryptedAuthTokenName,
      result.tokens.access.token,
      {
        expires: 7, //result.tokens.access.expires
        secure: true,
        sameSite: "strict",
      },
    );
    Cookies.set(
      AppConsts.authorization.currentUser,
      JSON.stringify(result.user),
      {
        expires: 7, //result.tokens.access.expires
        secure: true,
        sameSite: "strict",
      },
    );
  }

  async signOut() {
    this.isLoading = true;
    this.user = undefined;
    Cookies.remove(AppConsts.authorization.encryptedAuthTokenName, {
      path: "",
    });
    Cookies.remove(AppConsts.authorization.currentUser, { path: "" });
  }
}
export default AuthenticationStore;
