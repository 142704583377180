import { ProjectModel } from "@models/property/project.model";
import http from "@services/http.service";

class ProjectService {
  async fetch(params: any) {
    const res = await http.get("/api/project", {
      params,
    });
    return ProjectModel.assigns(res.data.results);
  }

  async filter(params: any) {
    const res = await http.get(`/api/project`, {
      params,
    });
    res.data.results = ProjectModel.assigns(res.data.results);
    return res.data;
  }

  async get(id: any) {
    const res = await http.get("/api/project", {
      params: { id },
    });
    return ProjectModel.assign(
      res.data.results?.length ? res.data.results[0] : {},
    );
  }

  async getBySlug(slug: any) {
    const res = await http.get("/api/project/${slug}");
    return ProjectModel.assign(res.data.results);
  }

  async getBySlugForPortal(slug: any) {
    const res = await http.get(`/api/project/${slug}`);
    return ProjectModel.assign(res.data.results);
  }

  public async addProject(body: any) {
    let result = await http.post("/api/project", body);
    return ProjectModel.assign(result.data.results);
  }

  public async updateProject(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/project`, data);
    return ProjectModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(`/api/project/active-or-deactivate`, null, {
      params: {
        id,
        active,
      },
    });
    return ProjectModel.assign(result.data);
  }
}

const projectService = new ProjectService();
export default projectService;
