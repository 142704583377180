import { PagedResultModel } from "@models/paged-result.model";
import { RequestContactModel } from "@models/cms/request-contact.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import requestService from "@services/cms/request-contact.service";

class RequestContactStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<RequestContactModel> = {
    results: [],
    totalCount: 0,
  };
  notionDocs: any = [];
  editRequestContact: RequestContactModel = new RequestContactModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initRequestContact(RequestContact?: any) {
    this.editRequestContact = RequestContact || new RequestContactModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await requestService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async filterNotion(params: any) {
    this.isLoading = true;
    this.notionDocs = await requestService
      .filterNotion(params)
      .finally(() => (this.isLoading = false));

    return this.notionDocs;
  }

  async addRequestContact(body: any) {
    this.isLoading = true;
    this.editRequestContact = await requestService
      .addRequestContact(body)
      .finally(() => (this.isLoading = false));
  }

  async updateRequestContact(body: any) {
    this.isLoading = true;
    this.editRequestContact = await requestService
      .updateRequestContact(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editRequestContact = await requestService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editRequestContact = await requestService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default RequestContactStore;
