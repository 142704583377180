import { PagedResultModel } from "@models/paged-result.model";
import IRoleModel, { RoleModel } from "@models/system/role.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import roleService from "@services/system/role.service";

class RoleStore {
  isLoading: boolean = false;
  aiRoleParams: any;
  pagedResult: PagedResultModel<IRoleModel> = {
    results: [],
    totalCount: 0,
  };
  editRole: RoleModel = new RoleModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setEditingRole(role: any) {
    this.editRole = role;
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await roleService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async addRole(body: any) {
    this.isLoading = true;
    this.editRole = await roleService
      .addRole(body)
      .finally(() => (this.isLoading = false));
  }

  async updateRole(body: any) {
    this.isLoading = true;
    this.editRole = await roleService
      .updateRole(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editRole = await roleService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editRole = await roleService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default RoleStore;
