import { PagedResultModel } from "@models/paged-result.model";
import { CategoryModel } from "@models/cms/category.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import categoryService from "@services/cms/category.service";
import { OptionModel } from "@/src/models/global";

class CategoryStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<CategoryModel> = {
    results: [],
    totalCount: 0,
  };
  editCategory: CategoryModel = new CategoryModel();
  siteOptions: OptionModel[] = [];

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initCategory(Category?: any) {
    this.editCategory = Category || new CategoryModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await categoryService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async filterOptions(params: any) {
    this.isLoading = true;
    params.page = 1;
    params.limit = 100;
    let result = await categoryService
      .filter(params)
      .finally(() => (this.isLoading = false));

    return result.results.map((item) => new OptionModel(item.slug, item.name));
  }

  async filterSiteOptions(params: any) {
    this.isLoading = true;
    params.page = 1;
    params.limit = 100;
    let result = await categoryService
      .filterSites(params)
      .finally(() => (this.isLoading = false));

    return result.results;
  }

  async add(body: any) {
    this.isLoading = true;
    this.editCategory = await categoryService
      .add(body)
      .finally(() => (this.isLoading = false));
  }

  async update(body: any) {
    this.isLoading = true;
    this.editCategory = await categoryService
      .update(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editCategory = await categoryService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editCategory = await categoryService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default CategoryStore;
