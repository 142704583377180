import { ISystemModel } from "../global";

interface IRoleModel {
  name: string;
  description: string;
  master: boolean;
  active: boolean;
  level: number;
  permissionIds: string[];
}

export class RoleModel implements IRoleModel, ISystemModel {
  id: string = "";
  name: string = "";
  description: string = "";
  master: boolean = false;
  active: boolean = true;
  level: number = 1;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  permissionIds: string[] = [];
  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new RoleModel(), obj);
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IRoleModel;
