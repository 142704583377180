import dayjs from "@/src/lib/dayjs";
import { ISystemModel } from "../global";

interface IProjectModel {
  name: string;
  description: string;
  shortDescription: string;
  numOfFloors: number;
  numOfProperties: number;
}

export class ProjectModel implements IProjectModel, ISystemModel {
  id: string = "";
  slug: string = "";
  name: string = "";
  shortDescription: string = "";
  description: string = "";
  photo: string = "";
  numOfFloors: number = 0;
  numOfProperties: number = 0;
  blocks: string[] = [];
  address: string = "";
  floorAreaMin: number = 0;
  floorAreaMax: number = 0;
  priceMin: number = 0;
  priceMax: number = 0;
  status: string = "";
  userId: string = "";
  seller: any;
  dateOfBuilding: dayjs.Dayjs = dayjs();
  dateOfDelivery: dayjs.Dayjs = dayjs();
  startDate: dayjs.Dayjs = dayjs();
  endDate: dayjs.Dayjs = dayjs();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new ProjectModel(), obj);
    newObj.dateOfBuilding = obj.dateOfBuilding
      ? dayjs(obj.dateOfBuilding)
      : undefined;
    newObj.dateOfDelivery = obj.dateOfDelivery
      ? dayjs(obj.dateOfDelivery)
      : undefined;
    newObj.startDate = obj.startDate ? dayjs(obj.startDate) : dayjs();
    newObj.endDate = obj.endDate ? dayjs(obj.endDate) : dayjs();

    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : undefined;

    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IProjectModel;
