import { PagedResultModel } from "@models/paged-result.model";
import http from "../http.service";
import { CampaignModel } from "@models/cms/campaign.model";

class CampaignService {
  public async filter(params: any): Promise<PagedResultModel<CampaignModel>> {
    let result = await http.get("/api/cms/campaign", { params });

    result.data.results = CampaignModel.assigns(result.data.results);
    return result.data;
  }
  public async filterNotion(params: any): Promise<any> {
    let result = await http.get("/api/cms/notion", { params });

    return result.data.results;
  }

  public async addCampaign(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.post("/api/cms/campaign", data);
    return CampaignModel.assign(result.data.results);
  }

  public async updateCampaign(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.patch(`/api/cms/campaign`, data);
    return CampaignModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/cms/campaign/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return CampaignModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/cms/campaign/${id}`);
    return CampaignModel.assign(result.data.results);
  }
}
const masterDataService = new CampaignService();
export default masterDataService;
