import dayjs from "@/src/lib/dayjs";
import { ISystemModel } from "../global";

interface IPropertyModel {
  name: string;
  description: string;
  shortDescription: string;
  floor: number;
  block: String;
  numOfFloors: number;
  numOfBedrooms: number;
  numOfBathrooms: number;
}

export class PropertyModel implements IPropertyModel, ISystemModel {
  id: string = "";
  name: string = "A-01-02";
  shortDescription: string = "";
  description: string = "";
  photo: string = "";
  floor: number = 1;
  block: String = "A";
  floorArea: number = 35;
  facing: String = "";
  uses: String = "stay";
  sovereignty: String = "ownership_certificate";
  furniture: String = "full";
  price: number = 1000000000;
  numOfFloors: number = 1;
  numOfBathrooms: number = 2;
  numOfBedrooms: number = 2;
  status: string = "";
  userId: string = "";
  startDate: dayjs.Dayjs = dayjs();
  endDate: dayjs.Dayjs = dayjs();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new PropertyModel(), obj);
    newObj.startDate = obj.startDate ? dayjs(obj.startDate) : dayjs();
    newObj.endDate = obj.endDate ? dayjs(obj.endDate) : dayjs();
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IPropertyModel;
