import { PagedResultModel } from "@models/paged-result.model";
import ISalaryModel, { SalaryModel } from "@models/finance/salary.model";
import http from "../http.service";

class SalaryService {
  public async filter(params: any): Promise<PagedResultModel<ISalaryModel>> {
    let result = await http.get("/api/finance/salary", { params });

    result.data.results = SalaryModel.assigns(result.data.results);
    return result.data;
  }

  public async addSalary(body: any) {
    let result = await http.post("/api/finance/salary", body);
    return SalaryModel.assign(result.data.results);
  }

  public async updateSalary(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/finance/salary`, data);
    return SalaryModel.assign(result.data.results);
  }

  public async addBulkSalary(body: any) {
    let result = await http.post("/api/finance/salary/bulk", body);
    return SalaryModel.assigns(result.data.results);
  }

  public async updateBulkSalary(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/finance/salary/bulk`, data);
    return SalaryModel.assigns(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/finance/salary/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return SalaryModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/finance/salary/${id}`);
    return SalaryModel.assign(result.data.results);
  }
}
const masterDataService = new SalaryService();
export default masterDataService;
