import ISignInModel from "@models/account/signinModel";
import ISignUpModel, { ISignUpResultModel } from "@models/account/signupModel";
import http from "./http.service";

class AuthService {
  public async signIn(input: ISignInModel): Promise<any> {
    let result = await http.post("/v1/auth/login", input);
    return result.data;
  }

  public async signOut(input: ISignInModel): Promise<any> {
    let result = await http.post("/v1/auth/logout", input);
    return result.data;
  }

  public async getProfile(input: ISignInModel): Promise<any> {
    let result = await http.post("/v1/auth/login", input);
    return result.data;
  }

  public async signUp(input: ISignUpModel): Promise<ISignUpResultModel> {
    const data = { ...input };
    delete data.confirmPassword;
    let result = await http.post("/v1/auth/register", data);
    return result.data;
  }
}

const authService = new AuthService();
export default authService;
