import { PagedResultModel } from "@models/paged-result.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { OrganizationModel } from "@models/property/organization.model";
import organizationService from "@services/property/organization.service";
import { OptionModel } from "@models/global";

class OrganizationStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<OrganizationModel> = {
    results: [],
    totalCount: 0,
  };
  filter: any = {};
  editOrganization: OrganizationModel = new OrganizationModel();

  inveslopers: OptionModel[] = [];
  agencies: OptionModel[] = [];

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async setFilter(filter: any) {
    this.filter = { ...this.filter, ...(filter || {}) };
  }

  async fetch(params: any) {
    this.isLoading = true;
    let result = await organizationService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editOrganization = await organizationService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }

  async addOrganization(body: any) {
    this.isLoading = true;
    body.active = true;
    this.editOrganization = await organizationService
      .addOrganization(body)
      .finally(() => (this.isLoading = false));
  }

  async updateOrganization(body: any) {
    this.isLoading = true;
    this.editOrganization = await organizationService
      .updateOrganization(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editOrganization = await organizationService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getInveslopers(params: any) {
    this.isLoading = true;
    params.group = "INVESLOPER";
    let result = await organizationService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.inveslopers = result.results.map(
      (item) => new OptionModel(item.id, item.name),
    );
    return this.inveslopers;
  }

  async getAgencies(params: any) {
    this.isLoading = true;
    params.group = "AGENCY";
    let result = await organizationService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.agencies = result.results.map(
      (item) => new OptionModel(item.id, item.name),
    );
    return this.agencies;
  }
}
export default OrganizationStore;
