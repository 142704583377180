interface IUserRevenueModel {
  userId: string;
  user: any;
  seo: number;
  education: number;
  ads: number;
  month: Date;
}

export class UserRevenueModel implements IUserRevenueModel {
  userId: string = "";
  user: any = undefined;
  seo: number = 0;
  education: number = 0;
  ads: number = 0;
  month: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new UserRevenueModel(), obj);
    newObj.month = obj.month ? new Date(obj.month) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    (objs || []).forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IUserRevenueModel;
