import { PagedResultModel } from "@models/paged-result.model";
import { CampaignModel } from "@models/cms/campaign.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import campaignService from "@services/cms/campaign.service";

class CampaignStore {
  isLoading: boolean = false;
  pagedResult: PagedResultModel<CampaignModel> = {
    results: [],
    totalCount: 0,
  };
  notionDocs: any = [];
  editCampaign: CampaignModel = new CampaignModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initCampaign(campaign?: any) {
    this.editCampaign = campaign || new CampaignModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await campaignService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async filterNotion(params: any) {
    this.isLoading = true;
    this.notionDocs = await campaignService
      .filterNotion(params)
      .finally(() => (this.isLoading = false));

    return this.notionDocs;
  }

  async addCampaign(body: any) {
    this.isLoading = true;
    this.editCampaign = await campaignService
      .addCampaign(body)
      .finally(() => (this.isLoading = false));
  }

  async updateCampaign(body: any) {
    this.isLoading = true;
    this.editCampaign = await campaignService
      .updateCampaign(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editCampaign = await campaignService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editCampaign = await campaignService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default CampaignStore;
