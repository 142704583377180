import { PagedResultModel } from "@models/paged-result.model";
import http from "@services/http.service";
import { LocationGroupModel } from "@models/property/location-group.model";

class LocationGroupService {
  async fetch(params: any) {
    const res = await http.get("/api/location-group", {
      params,
    });
    return LocationGroupModel.assigns(res.data.results);
  }

  public async filter(
    params: any,
  ): Promise<PagedResultModel<LocationGroupModel>> {
    let result = await http.get("/api/location-group", { params });

    result.data.results = LocationGroupModel.assigns(result.data.results);
    return result.data;
  }

  public async add(body: any) {
    let result = await http.post("/api/location-group", body);
    return LocationGroupModel.assign(result.data.results);
  }

  public async update(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/location-group`, data);
    return LocationGroupModel.assign(result.data.results);
  }

  public async updateBulk(body: any) {
    let result = await http.patch(`/api/location-group/bulk`, body);
    return LocationGroupModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/location-group/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return LocationGroupModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/location-group/${id}`);
    return LocationGroupModel.assign(result.data.results);
  }
}
const locationGroupService = new LocationGroupService();
export default locationGroupService;
