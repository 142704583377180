import { SalaryMaster } from "@prisma/client";
import { ISystemModel } from "../global";
import dayjs from "@/src/lib/dayjs";
import { UserRevenueModel } from "./user-revenue.model";

export const maxInsuranceSalary = 36000000;
export const insurancePercent = 10.5;
export const dependantDeduct4Each = 4400000;
export const maxLunchAllowance = 730000;
export const lunchAllowancePerDay = 35000;
export const maxTradeUnion = 150000;
export const taxByLevel = [
  {
    min: 0,
    max: 5000000,
    taxPercent: 0.05,
    previousValue: 0,
  },
  {
    min: 5000001,
    max: 10000000,
    taxPercent: 0.1,
    previousValue: 250000,
  },
  {
    min: 10000001,
    max: 18000000,
    taxPercent: 0.15,
    previousValue: 750000,
  },
  {
    min: 18000001,
    max: 32000000,
    taxPercent: 0.2,
    previousValue: 1650000,
  },
  {
    min: 32000001,
    max: 52000000,
    taxPercent: 0.25,
    previousValue: 3250000,
  },
  {
    min: 52000001,
    max: 80000000,
    taxPercent: 0.3,
    previousValue: 5850000,
  },
  {
    min: 80000001,
    max: 1000000000000,
    taxPercent: 0.35,
    previousValue: 9850000,
  },
];

interface ISalaryModel {
  userId: string;
  user: any;
  status: string;
  standardWD: number;
  lunchAllowanceDay: number;
  officialSalary: number;
  nightSalary: number;
  shiftAllowance: number;
  lunchAllowance: number;
  gasolineAllowance: number;
  mbAllowance: number;
  totalOTSalary: number;
  totalTaxableOT: number;
  anlSalary: number;
  grossIncome: number;
  socialInsuranceSalary: number;
  socialInsurancePercent: number;
  personalDeduction: number;
  noOfDependents: number;
  dependantDeductValue: number;
  taxableIncome: number;
  assessableIncome: number;
  totalPIT: number;
  tradeUnion: number;
  netTakeHome: number;
}

export class SalaryModel implements ISalaryModel, ISystemModel {
  id: string = "";
  userId: string = "";
  user: any;
  status: string = "active";
  standardWD: number = 0;
  lunchAllowanceDay: number = 0;
  officialSalary: number = 0;
  nightSalary: number = 0; // No tax
  shiftAllowance: number = 0;
  lunchAllowance: number = 0;
  gasolineAllowance: number = 0;
  mbAllowance: number = 0;
  contractSalary: number = 0;
  bonusFinishWork: number = 0;
  bonusBirthday: number = 0;
  bonusEmployeeYear: number = 0;
  bonusPerformingArts: number = 0;
  bonusAdsContent: number = 0;
  bonusSEO: number = 0;
  bonusEducation: number = 0;
  bonusResponsibility: number = 0;
  bonusAdvance: number = 0;
  totalOTSalary: number = 0;
  totalTaxableOT: number = 0;
  anlSalary: number = 0;
  grossIncome: number = 0;
  socialInsuranceSalary: number = 0;
  socialInsurancePercent: number = 10.5;
  personalDeduction: number = 11000000;
  noOfDependents: number = 0;
  dependantDeductValue: number = 0;
  taxableIncome: number = 0;
  assessableIncome: number = 0;
  totalPIT: number = 0;
  tradeUnion: number = 0;
  netTakeHome: number = 0;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  workingDate = dayjs().startOf("month");

  constructor(salaryMaster?: SalaryMaster, userId?: string) {
    this.userId = userId || "";
    if (!salaryMaster) {
      return;
    }
    const {
      contractSalary,
      gasolineAllowance,
      mbAllowance,
      noOfDependents,
      shiftAllowance,
    } = salaryMaster;
    this.contractSalary = contractSalary;
    this.gasolineAllowance = gasolineAllowance;
    this.mbAllowance = mbAllowance;
    this.noOfDependents = noOfDependents;
    this.shiftAllowance = shiftAllowance;
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new SalaryModel(), obj);
    newObj.active = obj.status === "active";
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    newObj.workingDate = obj.workingDate
      ? dayjs(obj.workingDate)
      : dayjs().startOf("month");
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }

  public static getBonus = (formValues: any) => {
    const {
      bonusFinishWork,
      bonusBirthday,
      bonusEmployeeYear,
      bonusPerformingArts,
      bonusAdsContent,
      bonusSEO,
      bonusEducation,
      bonusResponsibility,
      bonusAdvance,
    } = formValues;

    return Math.trunc(
      bonusFinishWork +
        bonusBirthday +
        bonusEmployeeYear +
        bonusPerformingArts +
        bonusAdsContent +
        bonusSEO +
        bonusEducation +
        bonusResponsibility +
        bonusAdvance,
    );
  };

  public static getTaxableIncome = (formValues: any) => {
    const {
      contractSalary,
      lunchAllowance,
      gasolineAllowance,
      mbAllowance,
      shiftAllowance,
      nightSalary,
      totalOTSalary,
    } = formValues;
    const totalBonus = this.getBonus(formValues);
    const lunchAllowanceNew =
      lunchAllowance > maxLunchAllowance ? maxLunchAllowance : lunchAllowance;

    return Math.trunc(
      contractSalary +
        lunchAllowanceNew +
        gasolineAllowance +
        mbAllowance +
        shiftAllowance +
        (nightSalary || 0) +
        (totalOTSalary || 0) +
        totalBonus,
    );
  };

  /*
   * All salary and allowance
   */
  static getGrossIncome = (formValues: any) => {
    const {
      contractSalary,
      lunchAllowance,
      gasolineAllowance,
      shiftAllowance,
      mbAllowance,
      nightSalary,
      otSalary,
    } = formValues;
    const totalBonus = this.getBonus(formValues);

    return Math.trunc(
      contractSalary +
        lunchAllowance +
        gasolineAllowance +
        shiftAllowance +
        mbAllowance +
        (nightSalary || 0) +
        (otSalary || 0) +
        totalBonus,
    );
  };

  /*
   * Total salary for tax calculation (after deduct)
   * afterDeduct = taxableIncome - personalDeduct - dependantDeduct - totalInsurance
   */
  static getAssessableIncome = (formValues: any, noOfDependents: number) => {
    const taxableIncome = this.getTaxableIncome(formValues);
    const { personalDeduction } = formValues;
    const dependantDeductValue = noOfDependents * dependantDeduct4Each;
    const socialInsurancePercent = this.getSocialInsurancePercent(formValues);
    let sAfterDeduct =
      taxableIncome -
      personalDeduction -
      dependantDeductValue -
      socialInsurancePercent;
    sAfterDeduct = sAfterDeduct < 0 ? 0 : sAfterDeduct;

    return Math.trunc(sAfterDeduct);
  };

  /*
   * Calculate total PIT from assessableIncome
   */
  static getTotalPIT = (
    formValues: any,
    noOfDependents: number | undefined,
  ) => {
    const assessableIncome = this.getAssessableIncome(
      formValues,
      noOfDependents ?? 0,
    );

    let totalPit = 0;
    taxByLevel.forEach((tax) => {
      if (assessableIncome >= tax.min && assessableIncome <= tax.max) {
        totalPit = assessableIncome * tax.taxPercent - tax.previousValue;
        return;
      }
    });
    return Math.trunc(totalPit);
  };

  /*
   * Get maximum salary for calculate insurance
   */
  static getSocialInsuranceSalary = (formValues: any) => {
    const { contractSalary } = formValues;
    let socialInsuranceSalary =
      contractSalary > maxInsuranceSalary ? maxInsuranceSalary : contractSalary;

    return socialInsuranceSalary;
  };

  /*
   * Calculate total insurance (base on setting percent)
   */
  static getSocialInsurancePercent = (formValues: any) => {
    return Math.trunc(
      (this.getSocialInsuranceSalary(formValues) * insurancePercent) / 100,
    );
  };

  /*
   * Official salary (after leaving deduction)
   */
  static getOfficialSalary = (formValues: any) => {
    const { contractSalary, standardWD, actualWD } = formValues;
    const totalBonus = this.getBonus(formValues);
    return Math.trunc(contractSalary * (actualWD / standardWD) + totalBonus);
  };

  /*
   * Official salary (after leaving deduction)
   */
  static getTotalBonus = (formValues: any) => {
    const { contractSalary, standardWD, actualWD } = formValues;
    return Math.trunc(contractSalary * (actualWD / standardWD));
  };

  /*
   * Total salary take home
   */
  static getNetTakeHome = (formValues: any, noOfDependents: number) => {
    const grossIncome = this.getGrossIncome(formValues);
    const totalPIT = this.getTotalPIT(formValues, noOfDependents);
    const insurancePercent = this.getSocialInsurancePercent(formValues);
    const { tradeUnion } = formValues;
    return Math.trunc(grossIncome - totalPIT - insurancePercent - tradeUnion);
  };

  static generateSalary = (salaryMaster: any, formValues: any) => {
    const { contractSalary, noOfDependents, haveTradeUnion } =
      salaryMaster || {};
    const tradeUnion = contractSalary * 0.01;

    const data = {
      ...formValues,
      officialSalary: this.getOfficialSalary(formValues),
      tradeUnion: haveTradeUnion
        ? tradeUnion > maxTradeUnion
          ? maxTradeUnion
          : tradeUnion
        : 0,
      socialInsuranceSalary: this.getSocialInsuranceSalary(formValues),
      socialInsurancePercent: this.getSocialInsurancePercent(formValues),
      dependantDeductValue: noOfDependents * dependantDeduct4Each,
      grossIncome: this.getGrossIncome(formValues),
      taxableIncome: this.getTaxableIncome(formValues),
      assessableIncome: this.getAssessableIncome(formValues, noOfDependents),
      totalPIT: this.getTotalPIT(formValues, noOfDependents),
      netTakeHome: this.getNetTakeHome(formValues, noOfDependents),
    };

    return data;
  };

  // Support to get bonus from revenue by percentage
  static getBonusFromRevenue = (revenue?: UserRevenueModel) => {
    if (!revenue) return {};
    const { seo, ads } = revenue;
    return {
      bonusSEO: seo || 0,
      bonusAdsContent: ads || 0,
    };
  };
}

export default ISalaryModel;
