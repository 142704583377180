import { PagedResultModel } from "@models/paged-result.model";
import IPostModel, { PostModel } from "@models/post/post.model";
import http from "./http.service";
import { SimplePostModel } from "../models/post/simple-post-model";
import { WpPostImageModel, WpPostModel } from "../models/post/wp-post.model";

class PostService {
  public async filter(params: any): Promise<PagedResultModel<PostModel>> {
    let result = await http.get(`/api/cms/post`, {
      params,
    });

    result.data.results = PostModel.assigns(result.data.results);
    return result.data;
  }

  public async filterImportPost(
    params: any,
  ): Promise<PagedResultModel<WpPostModel>> {
    try {
      // TODO: update to another external site
      let result = await http.get("https://mogi.vn/news/wp-json/wp/v2/posts", {
        params,
      });

      return {
        results: WpPostModel.assigns(result.data),
        totalCount: result.data.length,
      };
    } catch (e) {
      console.log(e);
      return { results: [], totalCount: 0 };
    }
  }

  public async filterWpPostImage(
    postAPI?: string,
    params?: any,
  ): Promise<PagedResultModel<WpPostImageModel>> {
    try {
      // TODO: update to another external site
      let result = await http.get(
        postAPI ?? "https://mogi.vn/news/wp-json/wp/v2/media",
        {
          params,
        },
      );

      return {
        results: WpPostImageModel.assigns(result.data),
        totalCount: result.data.length,
      };
    } catch (e) {
      console.log(e);
      return { results: [], totalCount: 0 };
    }
  }

  public async filterSimplePost(
    params: any,
  ): Promise<PagedResultModel<IPostModel>> {
    let result = await http.get("/v1/simple-post", { params });
    result.data.results = SimplePostModel.assigns(result.data.results);
    return result.data;
  }

  public async addPost(body: any) {
    let result = await http.post("/v1/ai-post", body);
    return PostModel.assign(result.data);
  }

  public async updatePost(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/cms/post`, data);
    return PostModel.assign(result.data);
  }

  public async updateBulkPostMeta(body: any) {
    const data = (body || []).map((item: any) => ({
      id: item.id,
      keywords: item.keywords,
      description: item.description,
    }));
    let result = await http.patch(`/api/cms/post-bulk`, data);
    return result.data;
  }

  public async getById(id: string) {
    let result = await http.get(`/v1/ai-post/${id}`);
    return PostModel.assign(result.data);
  }

  public async getBySlug(id: string) {
    let result = await http.get(`/api/cms/post/${id}`);
    return PostModel.assign(result.data.results);
  }
}

const postService = new PostService();
export default postService;
