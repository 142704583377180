import { PagedResultModel } from "@models/paged-result.model";
import IRoleModel, { RoleModel } from "@models/system/role.model";
import http from "../http.service";

class RoleService {
  public async filter(params: any): Promise<PagedResultModel<IRoleModel>> {
    let result = await http.get("/api/system/role", { params });

    result.data.results = RoleModel.assigns(result.data.results);
    return result.data;
  }

  public async addRole(body: any) {
    let result = await http.post("/api/system/role", body);
    return RoleModel.assign(result.data);
  }

  public async updateRole(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/system/role`, data);
    return RoleModel.assign(result.data);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/system/role/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return RoleModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/v1/ai-post/${id}`);
    return RoleModel.assign(result.data);
  }
}
const masterDataService = new RoleService();
export default masterDataService;
