import { ISystemModel } from "../global";

interface IMenuItemModel {
  id: string;
  slug: string;
  name: string;
  description?: string;
  price?: string;
  unit: string;
  image?: string;
  status: string;
}

export class MenuItemModel implements IMenuItemModel, ISystemModel {
  id: string = "";
  slug: string = "";
  name: string = "";
  description?: string = "";
  price?: string;
  unit: string = "";
  image?: string;
  status: string = "active";
  siteId?: string;
  variants?: any;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new MenuItemModel(), obj);
    newObj.createdAt = obj.createdAt ? new Date(obj.createdAt) : new Date();
    newObj.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : new Date();
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IMenuItemModel;
