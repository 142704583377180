import { PagedResultModel } from "@models/paged-result.model";
import { UserModel } from "@models/system/user.model";
import http from "../http.service";
import { SalaryMasterModel } from "@models/finance/salary-master.model";
import { OptionModel } from "@/src/models/global";

class UserService {
  public async filter(params: any): Promise<PagedResultModel<UserModel>> {
    let result = await http.get("/api/system/user", { params });

    result.data.results = UserModel.assigns(result.data.results);
    return result.data;
  }

  public async filterSites(
    params: any,
  ): Promise<PagedResultModel<OptionModel>> {
    let result = await http.get("/api/cms/site", { params });

    result.data.results = result.data.results.map(
      (item: any) => new OptionModel(item.id, item.name),
    );
    return result.data;
  }

  public async addUser(body: any) {
    let result = await http.post("/api/system/user", body);
    return UserModel.assign(result.data);
  }

  public async updateUser(body: any) {
    try {
      let result = await http.patch(`/api/system/user`, body);
      return UserModel.assign(result.data);
    } catch (e: any) {
      throw e;
    }
  }

  public async getById(id: string) {
    let result = await http.get(`/api/system/user/${id}`);
    return UserModel.assign(result.data);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/system/user/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return UserModel.assign(result.data);
  }

  public async addSalaryMaster(body: any) {
    let result = await http.post("/api/finance/salary-master", body);
    return UserModel.assign(result.data);
  }

  public async updateSalaryMaster(body: any) {
    let result = await http.patch(`/api/finance/salary-master`, body);
    return UserModel.assign(result.data);
  }

  public async getSalaryMasterByUserId(id: string) {
    let result = await http.get(`/api/finance/salary-master/${id}`);
    return SalaryMasterModel.assign(result.data.results);
  }
}
const masterDataService = new UserService();
export default masterDataService;
