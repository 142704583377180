import { PagedResultModel } from "@models/paged-result.model";
import http from "../http.service";
import { CategoryModel } from "@models/cms/category.model";
import { OptionModel } from "@/src/models/global";

class CategoryService {
  public async filter(params: any): Promise<PagedResultModel<CategoryModel>> {
    let result = await http.get("/api/cms/category", { params });

    result.data.results = CategoryModel.assigns(result.data.results);
    return result.data;
  }

  public async filterSites(
    params: any,
  ): Promise<PagedResultModel<OptionModel>> {
    let result = await http.get("/api/cms/site", { params });

    result.data.results = result.data.results.map(
      (item: any) => new OptionModel(item.id, item.name),
    );
    return result.data;
  }

  public async add(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.post("/api/cms/category", data);
    return CategoryModel.assign(result.data.results);
  }

  public async update(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.patch(`/api/cms/category`, data);
    return CategoryModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/cms/category/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return CategoryModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/cms/category/${id}`);
    return CategoryModel.assign(result.data.results);
  }
}
const masterDataService = new CategoryService();
export default masterDataService;
