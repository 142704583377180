import { PagedResultModel } from "@models/paged-result.model";
import http from "../http.service";
import { RequestContactModel } from "@models/cms/request-contact.model";

class RequestContactService {
  public async filter(
    params: any,
  ): Promise<PagedResultModel<RequestContactModel>> {
    let result = await http.get("/api/request-contact", { params });

    result.data.results = RequestContactModel.assigns(result.data.results);
    return result.data;
  }
  public async filterNotion(params: any): Promise<any> {
    let result = await http.get("/api/notion", { params });

    return result.data.results;
  }

  public async addRequestContact(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.post("/api/request-contact", data);
    return RequestContactModel.assign(result.data.results);
  }

  public async updateRequestContact(body: any) {
    const data = { ...body };
    delete data.remaining;
    delete data.posts;
    let result = await http.patch(`/api/request-contact`, data);
    return RequestContactModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/request-contact/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return RequestContactModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/request-contact/${id}`);
    return RequestContactModel.assign(result.data.results);
  }
}
const masterDataService = new RequestContactService();
export default masterDataService;
