import { BaseModel } from "../BaseModel";
import { UserModel } from "../system/user.model";

interface IWpPostModel {
  title: string;
  keyword: string;
  status: string;
}

const categoryMap: any = {
  "3074": "foreign",
  "3200": "property-experience",
  "1460": "for-buyer",
  "2488": "for-renter",
  "2976": "project",
  "2215": "property-news",
  "31": "property-investment",
  "29": "agency",
  "16": "property-knowledge",
  "17": "property-architectural",
  "19": "property-market",
  "28": "advice",
  "27": "property-law",
  "3183": "house-tips",
  "3073": "property-law",
  "2290": "property-architectural",
  "24": "property-report",
  "18": "feng-shui",
  "756": "property-in-vietnam",
  "3201": "gift-wish",
  "3070": "property-law",
  "3124": "travel-review",
  "26": "finance",
  "2487": "urban-planing",
  "20": "property-news",
  "23": "project",
  "3072": "property-law",
  "3071": "property-law",
  "2758": "property-video",
  "1366": "construction",
  "3075": "property-law",
};

export class WpPostModel extends BaseModel implements IWpPostModel {
  id: string = "";
  slug: string = "";
  title: string;
  keyword: string;
  outlines: string = "";
  description: string = "";
  content: string = "";
  status: string = "";
  userId: string = "";
  image: string = "";
  user: UserModel = new UserModel();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  originalData: string = "";
  siteId: string = "";

  constructor() {
    super();
    this.title = "";
    this.keyword = "";
    this.status = "";
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = this.copy(new WpPostModel(), obj);
    newObj.createdAt = new Date(obj.date);
    newObj.updatedAt = new Date(obj.modified);
    newObj.title = obj.title.rendered;
    newObj.content = obj.content.rendered;
    newObj.description = obj.excerpt.rendered;
    newObj.categories = obj.categories
      .map((id: string) => categoryMap[id] ?? "")
      .join(",");
    newObj.originalData =
      obj?.categories || obj?.author
        ? `{categories: ${obj.categories}, author: ${obj.author}, tags: ${obj.tags}}`
        : undefined;
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export class WpPostImageModel extends BaseModel {
  id: string = "";
  url: string = "";
  group: string = "";
  mimeType: string = "";
  status: string = "";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  slug: string = "";
  title: string = "";
  content: string = "";
  description: string = "";
  image: string = "";

  constructor() {
    super();
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = this.copy(new WpPostImageModel(), obj);
    newObj.createdAt = new Date(obj.date);
    newObj.updatedAt = new Date(obj.modified);
    newObj.group = "azoro";
    newObj.mimeType = obj.mime_type;
    newObj.url = obj.guid?.rendered;
    newObj.title = obj.title?.rendered;
    newObj.content = obj.content?.rendered;
    newObj.description = obj.excerpt?.rendered;
    newObj.image = obj.jetpack_featured_media_url;
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IWpPostModel;
