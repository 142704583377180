interface IOrganizationModel {
  name: string;
  photo: string;
  description: string;
  email: string;
  phone: string;
  hotline: string;
  website: string;
  twitter: string;
  facebook: string;
  linkedin: string;
  googlePlus: string;
  youtube: string;
  address: string;
}

export class OrganizationModel implements IOrganizationModel {
  id: string = "";
  name: string = "";
  description: string = "";
  photo: string = "";
  email: string = "";
  phone: string = "";
  hotline: string = "";
  website: string = "";
  twitter: string = "";
  facebook: string = "";
  linkedin: string = "";
  googlePlus: string = "";
  youtube: string = "";
  address: string = "";
  status: string = "";

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new OrganizationModel(), obj);

    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default IOrganizationModel;
