import { PagedResultModel } from "@models/paged-result.model";
import ISalaryModel, { SalaryModel } from "@models/finance/salary.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import salaryService from "@services/finance/salary.service";

class SalaryStore {
  isLoading: boolean = false;
  aiSalaryParams: any;
  pagedResult: PagedResultModel<ISalaryModel> = {
    results: [],
    totalCount: 0,
  };
  editSalary: SalaryModel = new SalaryModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initSalary(salary?: any) {
    this.editSalary = salary || new SalaryModel();
  }

  async filter(params: any) {
    this.isLoading = true;
    let result = await salaryService
      .filter(params)
      .finally(() => (this.isLoading = false));
    this.pagedResult = result;
    return this.pagedResult;
  }

  async addSalary(body: any) {
    this.isLoading = true;
    this.editSalary = await salaryService
      .addSalary(body)
      .finally(() => (this.isLoading = false));
  }

  async updateSalary(body: any) {
    this.isLoading = true;
    this.editSalary = await salaryService
      .updateSalary(body)
      .finally(() => (this.isLoading = false));
  }

  async addBulkSalary(body: any) {
    this.isLoading = true;
    await salaryService
      .addBulkSalary(body)
      .finally(() => (this.isLoading = false));
  }

  async updateBulkSalary(body: any) {
    this.isLoading = true;
    await salaryService
      .updateBulkSalary(body)
      .finally(() => (this.isLoading = false));
  }

  async activateOrDeactivate(id: any, isActive: boolean) {
    this.isLoading = true;
    this.editSalary = await salaryService
      .activateOrDeactivate(id, isActive)
      .finally(() => (this.isLoading = false));
  }

  async getById(id: string) {
    this.isLoading = true;
    this.editSalary = await salaryService
      .getById(id)
      .finally(() => (this.isLoading = false));
  }
}
export default SalaryStore;
