import { PropertyModel } from "@models/property/property.model";
import http, { httpExternal } from "@services/http.service";

class PropertyService {
  async fetch(params: any) {
    const res = await httpExternal.get("/api/property", { params });
    return PropertyModel.assigns(res.data.results);
  }

  async filter(params: any) {
    const res = await http.get(`/api/property`, {
      params,
    });
    res.data.results = PropertyModel.assigns(res.data.results);
    return res.data;
  }

  async get(id: any) {
    const url = `/api/property/${id}`;

    const res = await http.get(url);
    return PropertyModel.assign(res.data.results);
  }

  async getByIdForPortal(id: any) {
    const url = `/api/property/${id}`;

    const res = await http.get(url);
    return PropertyModel.assign(res.data.results);
  }

  async getBySlug(slug: string) {
    const url = `/api/property${slug}`;

    const res = await http.get(url);
    return PropertyModel.assign(res.data.results);
  }

  public async addProperty(body: any) {
    let result = await http.post("/api/property", body);
    return PropertyModel.assign(result.data.results);
  }

  public async updateProperty(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/property`, data);
    return PropertyModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(`/api/property/active-or-deactivate`, null, {
      params: {
        id,
        active,
      },
    });
    return PropertyModel.assign(result.data);
  }
}

const propertyService = new PropertyService();
export default propertyService;
