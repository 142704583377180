interface ILocationGroupModel {
  name: string;
  code: string;
  fullCode: string;
  level: string;
}

export class LocationGroupModel implements ILocationGroupModel {
  id: string = "";
  name: string = "";
  code: string = "";
  fullCode: string = "";
  level: string = "";

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new LocationGroupModel(), obj);

    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default ILocationGroupModel;
