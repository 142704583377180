import { ISystemModel } from "../global";

interface ISalaryMasterModel {
  userId: string;
  user: any;
  contractSalary: number;
  noOfDependents: number;
  haveTradeUnion: boolean;
}

export class SalaryMasterModel implements ISalaryMasterModel, ISystemModel {
  id: string = "";
  userId: string = "";
  user: any;
  contractSalary: number = 0;
  noOfDependents: number = 0;
  haveTradeUnion: boolean = false;
  gasolineAllowance: number = 0;
  shiftAllowance: number = 0;
  mbAllowance: number = 0;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor() {}

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new SalaryMasterModel(), obj);
    newObj.active = obj.status === "active";
    return newObj;
  }

  public static assigns(objs = []) {
    let results: any[] = [];
    objs?.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export default ISalaryMasterModel;
