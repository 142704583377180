import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { dateFormat } from "./constants";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export const startOfMonth = (date: any): Date => {
  if (!date) {
    return dayjs().startOf("month").toDate();
  }
  const dayjsDate = dayjs(date);
  return dayjsDate.startOf("month").toDate();
};

export const getDaysInMonth = (date: any): number => {
  if (!date) {
    return dayjs().daysInMonth();
  }
  const dayjsDate = dayjs(date);
  return dayjsDate.daysInMonth();
};

export const format = (date: any, formatStr: string = dateFormat) => {
  if (!date) {
    return dayjs().format(formatStr);
  }

  return dayjs(date).format(formatStr);
};

export default dayjs;
