import { PagedResultModel } from "@models/paged-result.model";
import IMasterDataModel, {
  MasterDataModel,
} from "@models/system/master-data.model";
import http from "../http.service";

class MasterDataService {
  public async filter(params: any): Promise<PagedResultModel<MasterDataModel>> {
    let result = await http.get("/api/system/master-data", { params });

    result.data.results = MasterDataModel.assigns(result.data.results);
    return result.data;
  }

  public async addMasterData(body: any) {
    let result = await http.post("/api/system/master-data", body);
    return MasterDataModel.assign(result.data);
  }

  public async updateMasterData(body: any) {
    let result = await http.patch(`/api/system/master-data`, body);
    return MasterDataModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/system/master-data/${id}`);
    return MasterDataModel.assign(result.data);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(
      `/api/system/master-data/active-or-deactivate`,
      null,
      {
        params: {
          id,
          active,
        },
      },
    );
    return MasterDataModel.assign(result.data);
  }
}
const masterDataService = new MasterDataService();
export default masterDataService;
