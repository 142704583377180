import { PagedResultModel } from "@models/paged-result.model";
import http from "../http.service";
import { MenuItemModel } from "@models/cms/menu-item.model";

class MenuService {
  public async filter(params: any): Promise<PagedResultModel<MenuItemModel>> {
    let result = await http.get("/api/cms/menu", { params });

    result.data.results = MenuItemModel.assigns(result.data.results);
    return result.data;
  }

  public async addMenu(body: any) {
    const data = { ...body };
    let result = await http.post("/api/cms/menu", data);
    return MenuItemModel.assign(result.data.results);
  }

  public async updateMenu(body: any) {
    const data = { ...body };
    let result = await http.patch(`/api/cms/menu`, data);
    return MenuItemModel.assign(result.data.results);
  }

  public async activateOrDeactivate(id: string, active: boolean) {
    let result = await http.patch(`/api/cms/menu/active-or-deactivate`, null, {
      params: {
        id,
        active,
      },
    });
    return MenuItemModel.assign(result.data);
  }

  public async getById(id: string) {
    let result = await http.get(`/api/cms/menu/${id}`);
    return MenuItemModel.assign(result.data.results);
  }
}
const masterDataService = new MenuService();
export default masterDataService;
